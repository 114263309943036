<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
      <v-col cols="12">
        <v-row
                justify="center"
        >
          <v-card tile>
            <v-layout align-center>
              <v-flex class="pa-16 pb-16 text-center">
                  <v-img width="250px" class="mx-auto" src="@/assets/images/blog/promocode.png" ></v-img>
                <v-card-text class="pb-0">
                  <v-form>
                    <v-subheader> <h5>Εισάγετε το PromoCode & κερδίστε το δώρο σας</h5></v-subheader>
                    <v-text-field v-model="promoCode" required
                            label=""/>
                  </v-form>
                </v-card-text>
                <v-card-text class="pb-0">
                  <p class="error" v-if="errors">{{ errors }}</p>
                </v-card-text>
               <v-card-actions>

               <v-btn height="50px" ile ripple depressed block color="secondary"
                            style="margin-top: 15%;"
                                    type="submit"
                                    value="submit" @click="promoCodeRedeem">
                                Αποκτήστε το
                            </v-btn>
               </v-card-actions>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
         
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios'

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return  {
          promoCode: '',
          errors: '',
      }
    },
    methods: {
        async promoCodeRedeem(e){
          
           if(this.promoCode == ''){
             this.promoCode = '';
              this.errors = "Ο κωδικός εξαργύρωσης είναι κενός.";
              return;
           }
          this.$loading(true)
            const response = await axios.post('external/me/transactions/coupon-claim', 
            {
                code: this.promoCode,
            }).then(response => {
                   this.$loading(false)
                   this.$swal({
                      title: "Συγχαρητήρια",
                      text: "Ο κωδικός εξαργυρώθηκε επιτυχώς.",
                      icon: "success", //built in icons: success, warning, error, info
                      timer: 3000,
                        buttons: {
                          confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                          },
                          cancel: {
                            text: "Cancel",
                            value: false,
                            visible: true,
                            className: "",
                            closeModal: true,
                          }
                        }
                    });
                    this.promoCode = '';
              }).catch(error => {
                    this.$loading(false)
                   this.promoCode = '';
                   this.errors = "Ο κωδικός εξαργύρωσης δεν είναι έγγυρος.";
              });

        }
           
       },
  };
</script>